
#countdown {
   // @countdown-color: contrast(@countdown-bg, black, white, 43%);
   // @countdown-bg2: darken(@countdown-bg, 8%);
   // background:         linear-gradient(@countdown-bg, @countdown-bg2);
   // background:      -o-linear-gradient(@countdown-bg, @countdown-bg2);
   // background:    -moz-linear-gradient(@countdown-bg, @countdown-bg2);
   // background: -webkit-linear-gradient(@countdown-bg, @countdown-bg2);
   background: url(../img/countdown.png);
   display: inline-block;
   height: 310px;
   width: 300px;
   //border-radius: 50%;
   position: fixed;
   top: 10px;
   right:10px;
   text-align: center;
   font-weight: 300;
   //box-shadow: 0 0 10px -4px black;
   z-index: 99;
   padding: 5px;
   border-radius: 3px;

// HOURS MINUTES SECONDS CONTAINER
  & > .others {
     display: inline-block;
     position: relative;
     top: 145px;
     padding: 2px;
     margin: 0;
     border-radius: 3px;
     font-size: 25px;

      //TIME TITLES
     .small-text {
       font-size: 13px;
       padding-top: 0px;

     }
 }

// HOURS MINUTES SECONDS VALUE
   & .others > span {
     padding: 10px;
     border-radius: 3px;
     background: rgba(255 , 255, 255, 0.4);
     display: inline-block;
     color: black;
     width: 50px;
     box-shadow: inset 0 0 6px -3px black;
     font-weight: 600;
}

// DAYS CONTAINER
   & > .main {

      // TIME TITLE
      .small-text {
        font-size: 13px;
        padding-top: 5px;
        position: absolute;
        top: 165px;
        width: 100px;
        left: 50%;
        margin-left: -50px;
      }
   }
// DAYS VALUE
    & .main > span {
      background: rgba(255, 255, 255, 0.4);
      color: black;
      font-weight: 400;
      border-radius: 3px;
      padding:0px 10px;
      position: absolute;
      left: 50%;
      margin-left: -45px;
      top:100px;
      font-size: 50px;
      width: 90px;
      box-shadow: inset 0 0 6px -3px black;


   }

// TEXT
   p {
      font-size: 14px;
      margin: 0;
      position: relative;
      top: 50px;
      width: 150px;
      margin: 0 auto;
   }

// MOBILE
   @media (max-width: 1200px) {
      height: 150px;
      width: 150px;
      background-size: cover;
      top: auto;
      bottom: 10px;

      & > .main {
         top: 20px;

         .small-text {
            top: 120px;
         }

      }
      & .main > span {
         padding: 5px;
         left: 50%;
         margin-left: -25px;
         top: 85px;
         font-size: 20px;
         width: 50px;

      }
      p {
         top: 15px;
         width: 100px;
         margin: 0 auto;
      }

      .others {
         display: none;
      }
   }

}
